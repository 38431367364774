import React from "react"
import { graphql, Link } from "gatsby"
import { Badge, Card, CardBody, CardSubtitle } from "reactstrap"
import Img from "gatsby-image"
import { slugify } from "../util/utilityFunctions"
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa"
import { DiscussionEmbed } from "disqus-react"
import Layout from "../components/layout/layout2"
import SEO from "../components/seo"

const SinglePost = ({ data, pageContext }) => {
  const post = data.markdownRemark.frontmatter

  const baseUrl = "https://www.pontogpp.com.br/"
  const postUrl =
    post.category === "cronicas" ? `${baseUrl}cronicas/` : `${baseUrl}tecnicos/`

  const disqusShortname = "pontogpp-1"
  const disqusConfig = {
    identifier: data.markdownRemark.id,
    title: post.title,
    url: postUrl + pageContext.slug,
  }

  return (
    <Layout>
      <SEO title={post.title} />
      <div className="container blog">
        <h1>{post.title}</h1>
        <Card>
          <Img
            className="card-image"
            fluid={post.image.childImageSharp.fluid}
          />
          <CardBody>
            <CardSubtitle className="date-info text-info">
              <p>Publicado em {post.date}</p>
              <ul className="post-tags">
                {post.tags.map((tag, index) => (
                  <li key={index}>
                    <Link to={`/tags/${slugify(tag)}`}>
                      <Badge color="primary" className="text-uppercase">
                        {tag}
                      </Badge>
                    </Link>
                  </li>
                ))}
              </ul>
            </CardSubtitle>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </CardBody>
        </Card>
        <h3 className="text-center">Compartilhe esta postagem</h3>
        <div className="text-center social-share-links">
          <ul>
            <li>
              <a
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                  postUrl +
                  pageContext.slug
                }
                className="facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare />
              </a>
            </li>
            <li>
              <a
                href={
                  "https://twitter.com/share?url=" +
                  postUrl +
                  pageContext.slug +
                  "&text=" +
                  post.title +
                  "&via" +
                  "twitterHandle"
                }
                className="twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitterSquare />
              </a>
            </li>
            <li>
              <a
                href={
                  "https://www.linkedin.com/shareArticle?url=" +
                  postUrl +
                  pageContext.slug
                }
                className="likedin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </li>
          </ul>
        </div>
        <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        category
        date(formatString: "DD/MM/YYYY")
        tags
        image {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SinglePost
